.page-title {
  font-size: 44px;
  line-height: 1.45;
  @include media-breakpoint-down(lg) {
    font-size: 40px;
  }
  @include media-breakpoint-down(md) {
    font-size: 36px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 32px;
  }
  font-weight: 800;
  margin-bottom: 24px;
}