@font-face {
  font-family: 'Nunito Sans';
  src: url('../../assets/fonts/NunitoSans-Bold.eot');
  src: url('../../assets/fonts/NunitoSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/NunitoSans-Bold.woff2') format('woff2'),
  url('../../assets/fonts/NunitoSans-Bold.woff') format('woff'),
  url('../../assets/fonts/NunitoSans-Bold.ttf') format('truetype'),
  url('../../assets/fonts/NunitoSans-Bold.svg#NunitoSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('../../assets/fonts/NunitoSans-ExtraBold.eot');
  src: url('../../assets/fonts/NunitoSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/NunitoSans-ExtraBold.woff2') format('woff2'),
  url('../../assets/fonts/NunitoSans-ExtraBold.woff') format('woff'),
  url('../../assets/fonts/NunitoSans-ExtraBold.ttf') format('truetype'),
  url('../../assets/fonts/NunitoSans-ExtraBold.svg#NunitoSans-ExtraBold') format('svg');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('../../assets/fonts/NunitoSans-Regular.eot');
  src: url('../../assets/fonts/NunitoSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/NunitoSans-Regular.woff2') format('woff2'),
  url('../../assets/fonts/NunitoSans-Regular.woff') format('woff'),
  url('../../assets/fonts/NunitoSans-Regular.ttf') format('truetype'),
  url('../../assets/fonts/NunitoSans-Regular.svg#NunitoSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('../../assets/fonts/NunitoSans-SemiBold.eot');
  src: url('../../assets/fonts/NunitoSans-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/NunitoSans-SemiBold.woff2') format('woff2'),
  url('../../assets/fonts/NunitoSans-SemiBold.woff') format('woff'),
  url('../../assets/fonts/NunitoSans-SemiBold.ttf') format('truetype'),
  url('../../assets/fonts/NunitoSans-SemiBold.svg#NunitoSans-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tumbly';
  src: url('../../assets/fonts/Tumbly.woff2') format('woff2'),
  url('../../assets/fonts/Tumbly.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}