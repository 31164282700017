/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;

  [dir="rtl"] & {
    float: right;
  }

  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}
.slick-arrow {
	position: absolute;
	top: 50%;
	//bottom: 0;
	transform: translateY(-50%);
	z-index: 3;
	width: 60px;
	
	img {
		//max-width: 100%;
		width: 100%;
	}
	
	&.slick-next {
		right: 0;
	}
	&.slick-prev {
		left: 0;
	}
}

/* Dots */

.slick-dots {
  position: absolute;
  bottom: 0;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  /*@include media-breakpoint-down(sm) {
    border: -20px;
  }*/
  li {
    position: relative;
    display: inline-block;
    height: 12px;
    width: 12px;
    margin: 0 6px;
    padding: 0;
    cursor: pointer;

    button {
      background: transparent;
      border: 1px solid transparent;
      display: block;
      height: 12px;
      width: 12px;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: 5px;
      cursor: pointer;

      &:hover, &:focus {
        outline: none;
      }
    }
  }
}
.slick-dots {
  bottom: 15px;
  li, li button {
    width: 10px;
    height: 10px;
  }
  li {
    button {
      background-color: rgba(255,255,255,0.6);
      @include border-radius(50%);
    }
    &.slick-active {
      button {
        background-color: white;
      }
    }
  }
}