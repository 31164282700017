#footer {
  background-color: $grey;
  padding: 4rem 0;
  overflow: hidden;
  @include media-breakpoint-down(sm) {
    text-align: center;
    padding: 2rem 0;
  }
  img {
    height: 2rem;
    @include media-breakpoint-down(sm) {
      margin-bottom: 24px;
    }
  }
  .footer-menu {
    @include flexbox();
    @include align-items();
    @include media-breakpoint-up(md) {
      @include justify-content(flex-end);
    }
    @include media-breakpoint-down(md) {
      @include flex-direction(column);
    }
    p {
      @include media-breakpoint-down(md) {
        margin-bottom: 12px;
      }
    }
    ul {
      li {
        position: relative;
        display: inline-block;
        font-size: 1rem;
        line-height: 1.5;
        padding-left: 1rem;
        @include media-breakpoint-up(md) {
          margin-left: 1rem;
        }
        @include media-breakpoint-down(md) {
          margin: 0 0.5rem;
        }
        @include media-breakpoint-down(md) {
          &:first-of-type {
            padding-left: 0;
            &:before {
              width: 0;
              background-color: transparent;
            }
          }
        }
        &:before {
          content: '';
          position: absolute;
          top: 9px;
          left: 0;
          width: 3px;
          height: 3px;
          @include border-radius(50%);
          background-color: $text-color;
        }
        a {
          &:hover {
            color: $red;
          }
        }
      }
    }
  }
}
